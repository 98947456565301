import { transformSeniorLivingTools } from '@sly/frontend/common/helpers/tools';
import ToolCard from '@sly/frontend/react/tools/components/ToolCard';

import ShowcaseCarousel from '../ShowcaseCarousel';

const SeniorLivingTools = ({
  title,
  description,
  tools,
}: {
  title: string;
  description: string;
  tools: any[];
}) => {
  const seniorLivingTools = transformSeniorLivingTools(tools || []);

  return (
    <ShowcaseCarousel
      header={title}
      description={description}
      buttonLink='/tools'
      buttonText='Explore more senior living tools'
      eventProps={{
        location: 'homepage - senior living tools',
        text: 'Explore more senior living tools',
      }}
    >
      {seniorLivingTools?.map((tool, index) => {
        return (
          <ToolCard
            key={index}
            item={tool}
            eventProps={{ location: 'homepage - tools', text: tool?.title }}
            target='_blank'
          />
        );
      })}
    </ShowcaseCarousel>
  );
};

export default SeniorLivingTools;
