import EntityTile from '@sly/frontend/react/card';
import { usePrefetch } from 'sly/services/api';

import ShowcaseCarousel from '../ShowcaseCarousel';

const ShowcaseCommunities = () => {
  const {
    requestInfo: { normalized },
  } = usePrefetch('getSearchPage', {
    'filter[award]': true,
    include: 'community',
  });

  return (
    <ShowcaseCarousel
      header='Seniorly’s Best of Senior Living'
      description='Our Annual Best of Senior Living Award celebrates the top senior living communities nationwide, recognizing those that go above and beyond in providing exceptional care, comfort, and support to their residents. Check out the communities that provide the highest quality of care - and make a difference in the lives of their residents.'
      buttonLink='/company/best-of-senior-living-awards-2024'
      buttonText='See all Best of Senior Living winners'
      eventProps={{
        location: 'homepage - best of senior living',
        text: 'See all Best of Senior Living winners',
      }}
      controlPosition='sides'
    >
      {normalized?.map((community, index) => {
        return (
          <EntityTile
            entity={community}
            intent='vertical'
            eventProps={{
              location: 'homepage - best of senior living',
              text: community.slug,
            }}
            className='max-w-sm'
            key={index}
          />
        );
      })}
    </ShowcaseCarousel>
  );
};

export default ShowcaseCommunities;
