import Tab from '@react/shared/components/Tab';
import Tabs from '@react/shared/components/Tabs';
import { MouseEvent, useState } from 'react';
import ExploreByCitySection from 'sly/homePage/components/ExploreByCity/ExploreByCitySection';
import { ExplorePopularCities } from 'sly/homePage/HomePage.types';
import Heading from 'sly/system/Heading';

export default function ExploreByCity({
  popularCities,
  title,
  hideCount = false,
}: {
  popularCities?: ExplorePopularCities;
  title?: string;
  hideCount?: boolean;
}) {
  const tabs = ['Assisted Living', 'Memory Care', 'Independent Living'];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  if (!popularCities) return null;
  const { assisted, independent, memory } = popularCities;
  const popularCitiesDict = {
    'Assisted Living': assisted || [],
    'Independent Living': independent || [],
    'Memory Care': memory || [],
  };
  if (
    popularCitiesDict['Assisted Living'].length === 0 &&
    popularCitiesDict['Independent Living'].length === 0 &&
    popularCitiesDict['Memory Care'].length === 0
  ) {
    return null;
  }
  const onClick = (e: MouseEvent<HTMLSpanElement>) => {
    setActiveTab((e.target as Element).id);
  };
  return (
    <section className='border-t px-8 py-12 md:py-20'>
      <div className='max-w-5xl lg:mx-auto'>
        <Heading
          font='font-t-l'
          className='my-0 mb-4 w-full max-w-2xl'
          data-tp-id='gen-Heading-762446cc-b149-4f1c-8f6f-c626ef89d7d3'
        >
          {title || 'Explore senior living communities by city'}
        </Heading>
        <div className='w-full'>
          <Tabs activeTab={activeTab} className='mx-0 px-0 normal-case'>
            {tabs.map((tab) => {
              if (
                !popularCitiesDict[tab as keyof typeof popularCitiesDict] ||
                !popularCitiesDict[tab as keyof typeof popularCitiesDict].length
              )
                return undefined;
              return (
                <Tab
                  key={tab}
                  id={tab}
                  onClick={onClick}
                  className='font-t-s-azo border-slate-base text-slate-base hover:cursor-pointer'
                >
                  {tab?.toLowerCase()?.charAt(0).toUpperCase() +
                    tab?.toLowerCase()?.slice(1)}
                </Tab>
              );
            })}
          </Tabs>
        </div>
        <div>
          {tabs.map((tab) => {
            if (
              !popularCitiesDict[tab as keyof typeof popularCitiesDict] ||
              !popularCitiesDict[tab as keyof typeof popularCitiesDict].length
            )
              return null;
            return (
              <ExploreByCitySection
                popularCitiesForSection={
                  popularCitiesDict[
                    tab as keyof typeof popularCitiesDict
                  ] as string
                }
                key={tab}
                active={activeTab === tab}
                tab={tab}
                hideCount={hideCount}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
