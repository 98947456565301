import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M19.7462 13.0506L4.406 19.5326C4.02485 19.6899 3.66434 19.6577 3.32447 19.4362C2.98462 19.2146 2.8147 18.8966 2.8147 18.4821V5.51795C2.8147 5.10347 2.98462 4.78544 3.32447 4.56388C3.66434 4.34233 4.02485 4.31017 4.406 4.46742L19.7462 10.9495C20.2147 11.1567 20.4489 11.5069 20.4489 12C20.4489 12.4931 20.2147 12.8433 19.7462 13.0506ZM5.00002 16.9103L16.6288 12L5.00002 7.0897V10.4104L11.0897 12L5.00002 13.5897V16.9103Z"/>
</svg>`;

const Send = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='send' svg={svg} {...props} />
  )
);

Send.displayName = 'SendIcon';

export default Send;
