/** @jsxImportSource react */

import { cn } from '@common/lib/clsxm';

import { NudgeType } from '../constants';

const Nudge = ({
  reply,
  isTooltip,
  handleClick,
}: {
  reply: NudgeType;
  isTooltip: boolean;
  handleClick: () => void;
}) => (
  <div
    key={reply.title}
    onClick={handleClick}
    className={cn(
      'rw-message max-w-xs cursor-pointer rounded border border-viridian-base bg-white text-left text-sm leading-5 text-viridian-base hover:bg-slate-lighter-95 hover:text-viridian-base',
      isTooltip ? 'px-2 py-1' : 'px-3 pt-1.5 !pb-1.5'
    )}
  >
    {reply.title}
  </div>
);

export default Nudge;
