/** @jsxImportSource react */
import { Button } from '@react/system';
import clsxm from '@sly/frontend/common/lib/clsxm';
import { Chevron, Close, Send, SlyChat } from '@sly/icons/react';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { GuidedFormType } from '../constants';

interface ChatWindowProps extends React.ComponentPropsWithoutRef<'div'> {
  isChatOpen: boolean;
  currentGuidedFlow?: GuidedFormType;
  guidedFlowTitle: string;
  isLoading: boolean;
  input: string;
  inputRef: React.RefObject<HTMLTextAreaElement> | null;
  formRef: React.RefObject<HTMLFormElement> | null;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  stop: () => void;
  onEnterPress: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  toggleChatOpen?: () => void;
  handleCloseGuidedFlow?: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  isChatOpen,
  currentGuidedFlow,
  guidedFlowTitle,
  isLoading,
  input,
  inputRef,
  formRef,
  handleInputChange,
  handleSubmit,
  stop,
  onEnterPress,
  toggleChatOpen,
  handleCloseGuidedFlow,
  children,
}) => {
  return (
    <div className='rw-conversation-container flex h-full w-full animate-fadeIn flex-col overflow-hidden bg-white align-baseline shadow-lg md:w-[400px] md:rounded-lg'>
      <div
        className={clsxm(
          'bg-white-base flex items-center justify-between border-b  border-slate-lighter-90  p-4 shadow md:rounded-t'
        )}
      >
        <div className='flex items-center gap-2'>
          {currentGuidedFlow && !!handleCloseGuidedFlow && (
            <button
              onClick={handleCloseGuidedFlow}
              className='rw-header-buttons hover:text-bg-slate-lighter-90 rounded-md p-1 hover:bg-slate-lighter-95'
            >
              <Chevron className='-rotate-90' color='text-slate-base' />
            </button>
          )}

          {guidedFlowTitle ? (
            <p className='font-b-m font-medium'>{guidedFlowTitle}</p>
          ) : (
            <div className='flex items-center gap-2'>
              <div
                className={clsxm(
                  'flex h-7 w-7 shrink-0 select-none items-center justify-center rounded-md border  bg-white p-4 text-viridian-base'
                )}
              >
                <SlyChat size='s' />
              </div>
              <div>
                <p className='font-b-s font-medium'>Seniorly virtual advisor</p>
              </div>
            </div>
          )}
        </div>
        {isChatOpen && !!toggleChatOpen && (
          <button
            className='rw-close-button hover:text-bg-slate-lighter-90 justify-self-end rounded-md p-1 hover:bg-slate-lighter-95'
            onClick={toggleChatOpen}
          >
            <Close className='rotate-180' color='text-slate-base' />
          </button>
        )}
      </div>

      {children}

      {isLoading && (
        <div className='ml-auto animate-fadeIn p-4'>
          <Button
            variant='secondary'
            className='!py-1 !px-2 text-sm'
            onClick={stop}
          >
            Stop generating
          </Button>
        </div>
      )}

      {!currentGuidedFlow && (
        <div className='p-4'>
          <form
            className='flex w-full flex-row items-center space-x-4 self-end rounded border border-slate-lighter-80 bg-white px-3 py-3 shadow'
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <TextareaAutosize
              minRows={1}
              onKeyDown={onEnterPress}
              value={input}
              maxRows={3}
              onChange={handleInputChange}
              className='rw-new-message w-full flex-1 resize-none bg-white outline-none'
              name='message'
              placeholder='Type your message here...'
              disabled={isLoading}
              autoFocus={false}
              autoComplete='off'
              ref={inputRef}
            />

            {isLoading ? (
              <div className='max-w-xs self-start rounded-xl bg-slate-lighter-95 px-4 py-2 text-base leading-5 text-black'>
                <div>
                  <div id='wave'>
                    <span className='rw-dot' />
                    <span className='rw-dot' />
                    <span className='rw-dot' />
                  </div>
                </div>
              </div>
            ) : (
              <button type='submit' disabled={isLoading}>
                <Send />
              </button>
            )}
          </form>
          <p className='font-b-xs mt-3 text-center text-slate-lighter-40'>
            Our virtual advisor is still learning and not all responses may be
            fully accurate
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
