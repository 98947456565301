import EntityReview from '@react/profile/v2/CommunityReviews/EntityReview';
import Carousel from '@react/shared/components/Carousel';
import NextImage from '@react/shared/components/Image';
import { usePrefetch } from 'sly/services/api';
import { Link } from 'sly/system';
export type Quote = {
  quote: string;
  author: string;
  communitySlug?: string;
  date?: string;
  stars?: number;
};

const CommunityReviews = ({
  quotes,
  communitySlugs,
}: {
  quotes: Quote[];
  communitySlugs: string;
}) => {
  const {
    requestInfo: { normalized },
  } = usePrefetch('getSearchPage', {
    'filter[id]': communitySlugs.split(/\s+/),
    include: 'community',
    exp: 'or',
    'page-size': quotes.length,
  });

  if (!normalized || !quotes.length) return <></>;

  return (
    <section className='border-b px-8 py-12'>
      <div className='mx-auto flex max-w-5xl flex-col gap-6'>
        <div className='space-y-4'>
          <h2 className='font-t-l max-w-3xl'>
            Real reviews from families just like yours
          </h2>
          <p className='font-b-m max-w-2xl'>
            Wondering how families really feel when they find the right senior
            living community? Check out reviews from real families who worked
            with Seniorly to find their perfect match.
          </p>
        </div>
        <Carousel
          itemsQty={quotes.length}
          className='mx-auto max-w-5xl'
          controlPosition='sides'
          eventProps={{ location: 'homepage - best of senior living' }}
          offset
        >
          {quotes?.map(({ quote, author, stars, communitySlug, date }) => {
            const community = normalized.find(
              ({ id }: { id: string }) => id === communitySlug
            );

            return (
              <div
                key={author + quote}
                className='flex min-h-full flex-col justify-between rounded-lg border border-slate-lighter-90'
              >
                <div className='p-4'>
                  <EntityReview
                    info={{ firstName: author, isVerified: true }}
                    value={stars || 5}
                    createdAt={date || ''}
                    comments={quote}
                    showReadMore
                    readMoreClickHandler={() => {
                      window.open(`${community?.url}#reviews`, '_blank');
                    }}
                    eventProps={{
                      location: 'homepage - best of reviews',
                      text: community?.id,
                    }}
                    bypassDateFormat
                  />
                </div>
                <Link
                  className='flex gap-2 bg-slate-lighter-95 p-4 text-black no-underline'
                  target='_blank'
                  href={community?.url}
                  rel='noreferrer noopener'
                >
                  <NextImage
                    path={community?.gallery?.images[0]?.path}
                    alt={community?.name}
                    width={48}
                    height={48}
                    layout='raw'
                    objectFit='fill'
                    className='h-12 w-12 rounded-lg'
                  />
                  <div className='flex flex-col overflow-hidden text-ellipsis whitespace-nowrap'>
                    <div className='font-t-xxs-azo'>{community?.firstLine}</div>
                    <div className='font-b-s overflow-hidden text-ellipsis whitespace-nowrap text-slate-lighter-40'>
                      {community?.addressString}
                    </div>
                    <div className='font-b-s overflow-hidden text-ellipsis whitespace-nowrap  text-slate-lighter-40'>
                      {community?.secondLine}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default CommunityReviews;
