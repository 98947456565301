import set from 'lodash/set';
import { useParams } from 'react-router-dom';
import {
  AGENT,
  COMMUNITY,
  ENTITY_TYPE,
  ENTITY_TYPE_ENDPOINTS,
  ENTITY_TYPE_PARAMS,
  LISTING,
} from 'sly/review/constants';

export const timeout = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const required = (value) => (value ? undefined : 'Required');

export const getEntitySlug = (entityType) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams();
  return entityType === COMMUNITY
    ? params[ENTITY_TYPE_PARAMS[entityType]][0]
    : params[ENTITY_TYPE_PARAMS[entityType]];
};

export const getReviewsInfo = (entity, entityType) => {
  let numReviews = 0;
  let reviewsValue = 0;
  if (entityType === AGENT || entityType === LISTING) {
    numReviews = entity?.aggregateRating?.numRatings;
    reviewsValue = entity?.aggregateRating?.ratingValue;
  } else if (entityType === COMMUNITY) {
    numReviews = entity?.propRatings?.numReviews;
    reviewsValue = entity?.propRatings?.reviewsValue;
  }
  return { numReviews, reviewsValue };
};

export const getEntityEndpoint = (entityType) => {
  return ENTITY_TYPE_ENDPOINTS[entityType];
};

export const getEntityType = (entityType) => {
  return ENTITY_TYPE[entityType];
};

export const getEntityInfo = (entityType) => {
  const endpoint = getEntityEndpoint(entityType);
  const type = getEntityType(entityType);

  return {
    endpoint,
    type,
  };
};

export const parseUpdateReviewPayload = (data) => {
  const attributes = {};
  const payload = {};

  set(attributes, 'value', data?.value);
  set(attributes, 'info.isVerified', data?.isVerified);
  set(attributes, 'comments', data?.comments);
  set(attributes, 'status', 2);

  set(payload, 'attributes', attributes);
  set(payload, 'type', 'Rating');

  return payload;
};

export const getRejectReviewPayload = () => {
  const attributes = {};
  const payload = {};

  set(attributes, 'status', -1);

  set(payload, 'attributes', attributes);
  set(payload, 'type', 'Rating');

  return payload;
};

export const getDeleteReviewPayload = () => {
  const attributes = {};
  const payload = {};

  set(attributes, 'status', -100);

  set(payload, 'attributes', attributes);
  set(payload, 'type', 'Rating');

  return payload;
};

export const splitName = (name = '') => {
  const [firstName, ...lastName] = name.split(' ').filter(Boolean);
  return {
    firstName,
    lastName: lastName.join(' '),
  };
};

const setInitialValuesFromQp = (qp) => {
  const initialValues = {};
  qp?.firstName &&
    set(initialValues, 'attributes.info.firstName', qp.firstName);
  qp?.lastName && set(initialValues, 'attributes.info.lastName', qp.lastName);
  qp?.email && set(initialValues, 'attributes.info.email', qp.email);
  qp?.value && set(initialValues, 'attributes.value', Number(qp.value));

  return initialValues;
};

export const setReviewsPayload = (
  attributes,
  rateableType,
  rateableSlug,
  user,
  qp = {}
) => {
  const payload = {};
  if (
    attributes.info &&
    attributes.info.relation &&
    attributes.info.relation !== 'Other'
  ) {
    delete attributes.info.relationOthers;
  }
  if (qp.email && attributes.info) {
    attributes.info.isVerified = true;
  }

  set(payload, 'attributes', attributes);
  set(payload, 'type', 'Rating');
  set(payload, 'attributes.rateableType', rateableType);
  set(payload, 'attributes.rateableSlug', rateableSlug);

  if (user) {
    set(payload, 'attributes.info.email', user?.email);
    const { firstName, lastName } = splitName(user?.name);
    set(payload, 'attributes.info.firstName', firstName);
    set(payload, 'attributes.info.lastName', lastName);
  }

  return payload;
};

export const getInitialValues = (qp) => (qp ? setInitialValuesFromQp(qp) : {});

const trimRatingCategoryItem = (categories) =>
  Object.values(categories)
    .filter(({ avgRating }) => avgRating > 0)
    .map(({ name, avgRating }) => ({ name, value: avgRating }));
const getListingRatingCategory = ({ ratingStats: { categories } }) =>
  categories ? trimRatingCategoryItem(categories) : [];
const getCommunityRatingCategory = ({ propRatings: { categories } }) =>
  categories ? trimRatingCategoryItem(categories) : [];

export const getRatingCategoryItems = (entityType, entity) => {
  if (entityType === LISTING) {
    return getListingRatingCategory(entity);
  }

  if (entityType === COMMUNITY) {
    if (!entity?.propRatings) {
      return [];
    }
    return getCommunityRatingCategory(entity);
  }

  return [];
};

export const isValidEmail = (email) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(String(email).toLowerCase());
};

export const validateEmail = (email) =>
  isValidEmail(email) ? undefined : 'Invalid email';

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const isValidQp = (qp) => {
  if (qp?.email && qp?.firstName) {
    if (isValidEmail(qp?.email)) {
      return true;
    }
  }
  return false;
};

export const isValidNpsQp = (qp) => {
  if (qp?.email && qp?.name) {
    if (isValidEmail(qp?.email)) {
      return true;
    }
  }
  return false;
};

export const checkAndValidateUserDetails = (qp, user, isNps) => {
  if (user) {
    return true;
  }

  let isValidUser = false;

  if (!user) {
    isValidUser = false;
  }

  if (!qp) {
    isValidUser = false;
  }

  if (qp && !isNps) {
    isValidUser = isValidQp(qp);
  }

  if (qp && isNps) {
    isValidUser = isValidNpsQp(qp);
  }

  return isValidUser;
};

export const isValidUser = (qp, user, isNps) =>
  checkAndValidateUserDetails(qp, user, isNps);

export const validateUser = (qp, user, isNps = false) => {
  return new Promise((resolve, reject) => {
    if (!isValidUser(qp, user, isNps)) {
      reject();
    } else {
      resolve();
    }
  });
};

export const buildQueryString = (qp) =>
  qp
    ? Object.keys(qp)
        .map((key) => `${key}=${qp[key]}`)
        .join('&')
    : '';

export const generateNpsRedirectQp = (qp) => {
  const queryStringObject = {};
  let name;
  name = qp?.firstName ? qp?.firstName : null;
  name = qp?.firstName && qp?.lastName && `${qp.firstName} ${qp.lastName}`;

  name && set(queryStringObject, 'name', name);

  qp?.email && set(queryStringObject, 'email', encodeURIComponent(qp.email));

  return buildQueryString(queryStringObject);
};

export const getInitialValuesForNps = (qp) => {
  const initialValues = {};
  if (!qp) {
    return {};
  }

  qp?.name && set(initialValues, 'attributes.info.name', qp?.name);
  qp?.email && set(initialValues, 'attributes.info.email', qp.email);
  qp?.score && set(initialValues, 'attributes.info.score', Number(qp.score));

  return initialValues;
};

export const setNpsPayload = (result, user) => {
  const payload = {};
  const { attributes, score, comment } = result;

  set(payload, 'attributes', attributes);
  set(payload, 'attributes.info.score', score);
  set(payload, 'attributes.info.comment', comment);
  set(payload, 'type', 'NPSSurvey');

  if (user) {
    set(payload, 'attributes.info.email', user?.email);
    set(payload, 'attributes.info.name', user?.name);
  }

  return payload;
};

export const getRelationText = (relation) => {
  if (relation === 'Other') {
    return '';
  }
  return `- ${relation}`;
};

export const getReviewerName = (review) => {
  let result = review.info?.firstName;
  if (review.info?.lastName) {
    result = `${result} ${review.info?.lastName[0]}`;
  }
  return result;
};
