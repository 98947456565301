import Accordion from '@sly/frontend/react/shared/components/Accordion';
import { Link } from 'sly/system';

const SeniorlyFAQs = ({
  FAQs,
}: {
  FAQs: { title: string; description: string }[];
}) => {
  if (!FAQs?.length) {
    return <></>;
  }

  return (
    <section className='border-b px-8 py-12'>
      <div className='mx-auto max-w-5xl space-y-12 rounded-xl'>
        <div className='max-w-3xl space-y-4'>
          <p className='font-l-m font-bold tracking-wider text-viridian-base'>
            FAQ
          </p>
          <h2 className='font-t-l'>Questions? We&apos;ve got answers.</h2>
          <p className='font-b-m'>
            Have a question about something else?{' '}
            <Link
              href='/company/contact-us'
              target='_blank'
              eventProps={{
                location: 'homepage - faq',
                text: 'contact us',
              }}
            >
              Contact us
            </Link>
          </p>
        </div>
        <div className='flex flex-col gap-6 md:grid md:grid-cols-2 '>
          {FAQs?.length > 0 &&
            FAQs?.map((faq, index) => (
              <div key={index}>
                <Accordion
                  title={faq.title}
                  content={faq.description}
                  blackHeader={true}
                  className='font-t-xs-azo'
                  parseRaw
                />
                <div
                  className='mt-l mb-l'
                  data-tp-id='gen-div-f23d1f50-3ea5-467e-8e32-ff6137bb716c'
                >
                  {FAQs.length - 1 - index >= 2 && (
                    <hr className='m-0 border-0 border-t border-t-slate-lighter-90 md:mb-l' />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default SeniorlyFAQs;
