/** @jsxImportSource react */

import clsxm from '@common/lib/clsxm';
import ChatIcon from '@react/virtual-advisor/components/ChatIcon';
import { MemoizedReactMarkdown } from '@react/virtual-advisor/components/MemoizedReactMarkdown';
import { Message } from 'ai/react';

export default function ChatMessage({
  message,
  isLoading,
  isLastMessage,
}: {
  message: Message;
  isLoading: boolean;
  isLastMessage: boolean;
}) {
  return (
    <div
      className={clsxm(
        'flex gap-2',
        message.role === 'user' ? 'max-w-xs self-end' : 'self-start'
      )}
    >
      {message.role === 'assistant' && (
        <ChatIcon
          className={isLoading && isLastMessage ? 'self-start' : 'self-end'}
        />
      )}

      <div
        className={clsxm(
          'flex-1 rounded-md px-4 py-2',
          message.role === 'user'
            ? 'bg-viridian-base text-white'
            : 'bg-slate-lighter-95 text-black'
        )}
      >
        <MemoizedReactMarkdown
          className={clsxm(
            'prose prose-sm break-words prose-headings:text-base prose-p:leading-relaxed prose-pre:p-0',
            message.role === 'user' ? 'text-white' : ''
          )}
          transformLinkUri={null}
          components={{
            p({ children }) {
              return <p className='mb-2 last:mb-0'>{children}</p>;
            },
            a({ children, href }) {
              return (
                <a target='_blank' href={href} rel='noreferrer'>
                  {children}
                </a>
              );
            },
          }}
        >
          {message.content}
        </MemoizedReactMarkdown>
      </div>
    </div>
  );
}
