export const REQUEST_TOUR = 'request_tour';
export const REQUEST_PRICING = 'request_pricing';
export const FALLBACK = 'fallback';
export const APPLY_FILTERS = 'apply_filters';
export const ASK_COMMUNITY_QUESTION = 'ask_community_question';
export const ANSWER_CITY_INSIGHTS = 'answer_city_insights';
export const OUT_OF_SCOPE = 'out_of_scope';
export const GET_PRICING_CTA = 'get_pricing_cta';
export const OPEN_BOT = 'open_bot';
export const MOUNT_BOT = 'mount_bot';
export const GET_RECOMMENDATIONS = 'get_recommendations';
export const SEE_COMMUNITY_DETAILS = 'see_community_details';
export const RECOMMENDATION_REQUEST_PRICING = 'recommendation_request_pricing';
export const REFRESH_RECOMMENDATIONS = 'referesh_recommendations';
export const REQUEST_TOUR_FROM_RECOMMENDATIONS =
  'request_tour_from_recommendations';
export const FIND_COMMUNITY_IN_AREA = 'find_community_in_area';
export const OPEN_BOT_WITH_MESSAGE_NEXT = 'open_bot_with_message_next';

export const GUIDED_FORMS = [
  REQUEST_TOUR,
  REQUEST_PRICING,
  FALLBACK,
  RECOMMENDATION_REQUEST_PRICING,
  REQUEST_TOUR_FROM_RECOMMENDATIONS,
  FIND_COMMUNITY_IN_AREA,
] as const;
export type GuidedFormType = (typeof GUIDED_FORMS)[number];

export const GUIDED_FLOW_TITLE_MAP: {
  [key in GuidedFormType]: string;
} = {
  [REQUEST_TOUR]: 'Schedule a tour',
  [REQUEST_PRICING]: 'Get personalized pricing',
  [FALLBACK]: 'Ask a question',
  [RECOMMENDATION_REQUEST_PRICING]: 'Get personalized pricing',
  [REQUEST_TOUR_FROM_RECOMMENDATIONS]: 'Schedule a tour',
  [FIND_COMMUNITY_IN_AREA]: 'Find communities in your area',
};

export const VALID_BACKEND_FUNCTIONS = [
  ASK_COMMUNITY_QUESTION,
  ANSWER_CITY_INSIGHTS,
  OUT_OF_SCOPE,
  GET_RECOMMENDATIONS,
  REFRESH_RECOMMENDATIONS,
  SEE_COMMUNITY_DETAILS,
];

export const guidedFlowNudges = [
  {
    content_type: 'text',
    title: 'Request a tour',
    payload: 'Please request a tour for me.',
    isDefaultNudge: false,
  },
  {
    content_type: 'text',
    title: 'Pricing',
    payload: 'What is the pricing for this community?',
    isDefaultNudge: false,
  },
];

export type NudgeType = {
  content_type: string;
  title: string;
  payload: string;
  isDefaultNudge: boolean;
};

export const getAllNudges = (communityName?: string): NudgeType[] => {
  const communityNameText = communityName || 'this';
  return [
    {
      content_type: 'text',
      title: 'Get personalized recommendations',
      payload:
        'Could you provide me with some personalized recommendations based on my preferences?',
      isDefaultNudge: true,
    },
    {
      content_type: 'text',
      title: 'Nearby Amenities',
      payload: `Can you inform me about any nearby amenities of ${communityNameText} community?`,
      isDefaultNudge: false,
    },
    ...guidedFlowNudges,
    {
      content_type: 'text',
      title: 'Summary of the community',
      payload: `Can you give me a brief summary of ${communityNameText} community?`,
      isDefaultNudge: false,
    },
    {
      content_type: 'text',
      title: 'Location',
      payload: `Can you tell me more about the location of ${communityNameText} community?`,
      isDefaultNudge: false,
    },
    {
      content_type: 'text',
      title: 'Care',
      payload: `What types of care services are offered in ${communityNameText} community?`,
      isDefaultNudge: false,
    },
    {
      content_type: 'text',
      title: 'Reviews',
      payload: `Can you give me a summary of the reviews for ${communityNameText} community?`,
      isDefaultNudge: false,
    },
    {
      content_type: 'text',
      title: 'Community Amenities',
      payload: `What amenities does ${communityNameText} community have?`,
      isDefaultNudge: false,
    },
    {
      content_type: 'text',
      title: 'Neighborhood',
      payload: `What can you tell me about the neighborhood of ${communityNameText} community?`,
      isDefaultNudge: false,
    },
  ];
};

export const searchPageNudges: NudgeType[] = [
  {
    content_type: 'text',
    title: 'Get personalized recommendations',
    payload:
      'Could you provide me with some personalized recommendations based on my preferences?',
    isDefaultNudge: true,
  },
];

export const nudgesForDefaultPages: NudgeType[] = [
  {
    content_type: 'text',
    title: 'Get Started',
    payload: 'Can you provide me with some personalized recommendations?',
    isDefaultNudge: true,
  },
  {
    content_type: 'text',
    title: 'Find a community in my area',
    payload: 'Can you help me find a community in my area?',
    isDefaultNudge: true,
  },
];
