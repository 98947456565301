import Image from '@react/shared/components/Image';
import { Link } from 'sly/system';

import ShowcaseCarousel from '../ShowcaseCarousel';

const ShowcaseArticles = ({
  header,
  description,
  buttonLink,
  buttonText,
  articles,
  eventProps,
  showKeySection = false,
  showCompassSection = false,
}) => {
  if (!articles || articles.length === 0) {
    return <></>;
  }

  return (
    <ShowcaseCarousel
      header={header}
      description={description}
      buttonLink={buttonLink}
      buttonText={buttonText}
      eventProps={eventProps}
      showKeySection={showKeySection}
      showCompassSection={showCompassSection}
      controlPosition='sides'
    >
      {articles?.map((article, index) => {
        return (
          <div key={index} className='flex flex-col gap-4'>
            <Image
              src={article?.mainImg?.url}
              alt={article.title}
              layout='raw'
              className='h-56 w-full rounded-xl object-cover'
              width={340}
              height={238}
            />
            <h3 className='font-t-s'>{article.title}</h3>
            <p className='font-b-s'>{article.shortDescription}</p>
            {article?.articleLink && (
              <Link
                href={article?.articleLink}
                target='_blank'
                eventProps={{
                  ...eventProps,
                  text: article?.slug,
                }}
              >
                Read the full article
              </Link>
            )}
          </div>
        );
      })}
    </ShowcaseCarousel>
  );
};

export default ShowcaseArticles;
