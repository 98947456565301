/** @jsxImportSource react */
import { eventNames, useEvents } from '@react/services/events';
import Rating from '@react/shared/components/Rating';
import cx from 'classnames';
import dayjs from 'dayjs';
import { bool, number, object, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Verified } from 'sly/icons';
import { getReviewerName } from 'sly/review/helpers';

const EntityReview = ({
  value,
  createdAt,
  comments,
  info,
  readMoreClickHandler,
  showReadMore,
  bypassDateFormat = false,
  eventProps = {},
}) => {
  const [enableReadmore, setEnableReadMore] = useState(showReadMore);
  const commentRef = useRef(null);
  const { events } = useEvents();
  const readMoreHandler = () => {
    if (readMoreClickHandler) {
      events.track(eventNames.LinkClicked, eventProps);
      readMoreClickHandler();
    }
  };

  useEffect(() => {
    if (commentRef.current.clientHeight < commentRef.current.scrollHeight) {
      setEnableReadMore(showReadMore && true);
    } else {
      setEnableReadMore(false);
    }
  }, [commentRef, showReadMore]);

  return (
    <div>
      <div className='flex justify-between'>
        <div className='font-b-s font-medium text-slate-base'>
          {info && getReviewerName({ info })}
        </div>
        <div className='font-b-s text-slate-lighter-40'>
          {bypassDateFormat ? createdAt : dayjs(createdAt).format('MMMM YYYY')}
        </div>
      </div>
      <div className='flex justify-between'>
        <div>
          <Rating width={4} value={value} color='primary' variation='base' />
        </div>
        <div className='text-slate-lighter-40'>
          {info?.isVerified && (
            <div
              className='ml-s flex items-center'
              data-tp-id='gen-div-9b5c8d79-b66b-480b-aa7d-61d237bb1987'
            >
              <Verified size='xs' color='text-green-base' />
              <span
                className='font-b-s ml-xxs text-green-base'
                data-tp-id='gen-span-aaa3b3b8-153f-4a27-9569-2b4a3b0fba56'
              >
                Verified Review
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(
          'font-b-m relative mt-m flex min-h-[144px] md:min-h-min',
          enableReadmore && 'h-[144px] line-clamp-6 md:h-[unset]'
        )}
        ref={commentRef}
      >
        <div>{comments}</div>
      </div>
      {enableReadmore && (
        <div
          className='font-t-xs-azo mt-4 cursor-pointer bg-white text-viridian-base'
          onClick={readMoreHandler}
        >
          Read More
        </div>
      )}
    </div>
  );
};

EntityReview.propTypes = {
  value: number.isRequired,
  createdAt: string.isRequired,
  comments: string.isRequired,
  verified: bool,
  info: object,
  showReadMore: bool,
};

EntityReview.defaultProps = {
  info: {},
};

export default EntityReview;
