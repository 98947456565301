export const COMMUNITY = 'community';
export const LISTING = 'listing';
export const AGENT = 'agent';

export const ENTITY_TYPE = {
  [COMMUNITY]: 'Property',
  [LISTING]: 'Listing',
  [AGENT]: 'PartnerAgent',
};

// TODO
export const ENTITY_TYPE_ENDPOINTS = {
  [COMMUNITY]: 'getCommunity',
  [LISTING]: 'getListing',
  [AGENT]: 'getAgent',
};

export const ENTITY_TYPE_PARAMS = {
  [COMMUNITY]: 'communitySlug',
  [LISTING]: 'id',
  [AGENT]: 'agentSlug',
};

export const RATING_TYPE_TUPLE = [
  ['cleanliness', 'Cleanliness'],
  ['staff', 'Staff'],
  ['careService', 'Care Services'],
  ['mealDining', 'Meals & dining'],
  ['activity', 'Activities'],
  ['value', 'Value'],
];

export const AGENT_SURVEY_PATH = '/survey/agent/:slug';
export const NPS_SURVEY_PATH = '/survey/nps';

export const ENTITY_REVIES_BASE_PATH = '/wizards/review';
export const ENTITY_REVIES_PATH = `${ENTITY_REVIES_BASE_PATH}/:entityType/:slug`;

export const RATING_VALUES = [1, 2, 3, 4, 5];

export const CONNECTION_TO_COMMUNITY_OPTIONS = [
  {
    label: 'I’m a current/former resident',
    value: 'Resident',
  },
  {
    label: 'I’ve toured this community',
    value: 'Toured community',
  },
  {
    label: 'I’m a loved one of a resident',
    value: 'Loved one of resident',
  },
  {
    label: 'I work in the industry',
    value: 'Industry review',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
