import SearchBoxContainer from '@sly/frontend/react/search/components/SeachBoxContainer';

const PersonalizedSearch = ({
  onCurrentLocation,
}: {
  onCurrentLocation: any;
}) => {
  return (
    <section className='border-b px-8 py-12'>
      <div className='mx-0 flex max-w-5xl flex-col items-center justify-center rounded-xl bg-viridian-lighter-90 px-12 py-10 md:py-20 md:px-32 lg:mx-auto'>
        <section className='w-full max-w-md space-y-6 lg:max-w-none'>
          <h2 className='font-t-l text-center'>
            Find personalized senior living options in your area
          </h2>
          <SearchBoxContainer
            eventProps={{
              location: 'homepage - search box - personalized',
            }}
            onCurrentLocation={onCurrentLocation}
            inputClassName='rounded-full h-14 shadow-subtle'
            className='mx-auto max-w-lg'
            newSearchBox={true}
            include='community'
            placeholder='Search by city, state, ZIP code, community name'
          />
        </section>
      </div>
    </section>
  );
};

export default PersonalizedSearch;
