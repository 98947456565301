import Image from '@react/shared/components/Image';
import { cva } from 'class-variance-authority';
import clsxm from 'sly/lib/clsxm';
import { VariantProps } from 'sly/lib/cva';
import { assetPath } from 'sly/lib/path';

export const SeniorlyReasonsIntent = cva(
  'gap-6 rounded-xl border border-harvest-lighter-80 bg-harvest-lighter-90 shadow-subtle',
  {
    variants: {
      intent: {
        normal: 'flex flex-col p-8 justify-between',
        reverse: 'flex flex-col-reverse justify-between',
      },
      size: {
        small: 'col-span-12 md:col-span-5',
        large: 'col-span-12 md:col-span-7',
      },
      order: {
        first: 'order-2 md:order-1',
        second: 'order-1 md:order-2',
        default: '',
      },
    },
  }
);

export type SeniorlyReasonsVariants = VariantProps<
  typeof SeniorlyReasonsIntent
>;

const reasons: ReasonProps[] = [
  {
    image: {
      src: 'uploads/react-assets/home/communityMap.png',
      alt: 'Seniorly is a free service for families',
      className: 'ml-0 w-full lg:ml-auto lg:max-w-md',
    },
    title: 'Browse over 66,000 senior living communities',
    description:
      'Seniorly offers the largest online directory of senior living communities, so you can easily find the best options in your neighborhood.',
    intent: 'normal',
    size: 'large',
    order: 'default',
  },
  {
    image: {
      src: 'uploads/react-assets/home/slyscore 3.png',
      alt: 'Seniorly community score',
      className: 'mx-auto w-full px-8 sm:p-0 lg:px-8',
    },
    title: 'Seniorly Community Score®',
    description:
      'Check out our Seniorly Community Score, a comprehensive metric that makes it easier and less stressful to find the best communities in your area.',
    intent: 'reverse',
    size: 'small',
    order: 'default',
  },
  {
    image: {
      src: 'uploads/react-assets/home/image 503.png',
      alt: 'Seniorly virtual advisor',
      className: 'mx-auto w-full px-8 sm:p-0 lg:px-8',
    },
    title: 'Questions at 3 am?',
    description:
      'Seniorly’s Virtual Advisor offers thoughtful recommendations, guiding you to the communities that match your needs - day or night.',
    intent: 'reverse',
    size: 'small',
    order: 'first',
  },
  {
    image: {
      src: 'uploads/react-assets/home/Frame 17351.png',
      alt: 'Seniorly advisors',
      className: 'mx-auto w-full px-8 sm:p-0 lg:px-8',
    },
    title: 'Seniorly advisors: Expert guidance, every step of the way.',
    description:
      'From the first step to your next steps, our Seniorly advisors have the experience you need and the compassion you deserve to help you find the perfect senior living community. All at no cost to you.',
    intent: 'normal',
    size: 'large',
    order: 'second',
  },
];

interface ReasonProps extends SeniorlyReasonsVariants {
  image: {
    src: string;
    alt: string;
    className: string;
  };
  title: string;
  description: string;
  className?: string;
}

export const Reason = ({
  image,
  title,
  description,
  intent,
  size,
  order,
  className,
}: ReasonProps) => {
  return (
    <div
      className={clsxm(
        SeniorlyReasonsIntent({ intent, size, order }),
        className
      )}
    >
      <Image
        src={assetPath(image.src)}
        alt={image.alt}
        className={image.className}
        width={500}
        height={300}
        layout='raw'
      />
      <div className={clsxm('space-y-4', intent === 'reverse' && 'px-8 pt-8')}>
        <h2 className='font-t-s'>{title}</h2>
        <p className='font-b-m'>{description}</p>
      </div>
    </div>
  );
};

const SeniorlyReasons = () => {
  return (
    <section className='border-b px-8 pb-12 pt-8 md:pb-20'>
      <div className='mx-0 max-w-5xl space-y-12 rounded-xl lg:mx-auto'>
        <div className='max-w-3xl space-y-4'>
          <p className='font-l-m tracking-wider text-viridian-base'>
            WHY CHOOSE SENIORLY
          </p>
          <h2 className='font-t-l max-w-lg'>
            Make your senior living decision with confidence and clarity
          </h2>
        </div>
        <div className='grid h-full grid-cols-12 gap-6'>
          {reasons.map((reason, index) => (
            <Reason
              key={index}
              image={reason.image}
              title={reason.title}
              description={reason.description}
              intent={reason.intent}
              size={reason.size}
              order={reason.order}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SeniorlyReasons;
