import {
  getEntityList,
  getEntityListWithSlugs,
} from '@react/shared/helpers/getEntityList';
import { populate } from '@sly/core/constants/populate';
import HomePage from 'sly/homePage';
import { getArticleTagsRequestParams } from 'sly/resourceCenter/helpers';
import { createApiClient } from 'sly/services/api';
import apiEndpoints from 'sly/services/api/endpoints';

import defaults, {
  populateHomePage,
  WrappedRawHomepageData,
} from '../pagesDefaults/homepage';

interface CustomError {
  status: number;
  headers: Record<string, string>;
  type?: string;
}

interface HomePageReturn {
  props: HomePageReturnProps;
}

interface HomePageReturnProps {
  status?: number;
  initialApiState: WrappedRawHomepageData;
}

interface HomePageNotFound {
  notFound: boolean;
}

export default HomePage;

function errorIsStrapiError(error: any): error is CustomError {
  return 'status' in error || error?.type === 'invalid-json';
}

export async function getServerSideProps(): Promise<
  HomePageReturn | HomePageNotFound
> {
  const { api, store } = createApiClient(apiEndpoints);

  try {
    const [homepagePromise] = await Promise.all(
      [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getHomePageInfo.asAction({
          populate: populate.homePage,
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getSearchPage.asAction({
          'filter[toc]': 'assisted-living',
          include: 'community',
          'filter[boost]': true,
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getSearchPage.asAction({
          'filter[toc]': 'memory-care',
          include: 'community',
          'filter[boost]': true,
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getSearchPage.asAction({
          'filter[toc]': 'independent-living',
          include: 'community',
          'filter[boost]': true,
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getArticle.asAction(getArticleTagsRequestParams({}, 'CAREGIVERS')),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getArticle.asAction(
          getArticleTagsRequestParams({}, ' ASSISTED_LIVING FINANCE')
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getArticle.asAction(
          getArticleTagsRequestParams({}, ' CITY_GUIDES MARKET_TRENDS')
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        api.getSearchPage.asAction({
          'filter[award]': true,
          include: 'community',
        }),
      ].map(store.dispatch)
    );
    await getEntityList(
      homepagePromise,
      'body.data.communitiesList',
      api,
      store
    );

    const requestInfo = store.getRequestInfo(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      api.getHomePageInfo.actionName,
      JSON.stringify({ populate: populate.homePage })
    );

    const advisorReviewsSlugs =
      requestInfo?.body?.data?.advisorReviews?.quotes?.map(
        (quote: any) => quote.advisorSlug
      ) || [];

    const communityReviewsSlugs =
      requestInfo?.body?.data?.communityReviews?.quotes?.map(
        (quote: any) => quote.communitySlug
      ) || [];

    await getEntityListWithSlugs(
      homepagePromise,
      advisorReviewsSlugs,
      api,
      store,
      false
    );
    await getEntityListWithSlugs(
      homepagePromise,
      communityReviewsSlugs,
      api,
      store
    );
  } catch (e: unknown) {
    console.error('error', e);
    if (errorIsStrapiError(e)) {
      if (e?.status === 503 || e?.type === 'invalid-json') {
        return {
          props: {
            initialApiState: {
              getHomePageInfo: {
                [populateHomePage]: defaults,
              },
            },
            status: 503,
          },
        };
      }
      if (
        e?.status === 404 &&
        e?.headers['x-powered-by'] === 'Strapi <strapi.io>'
      ) {
        return {
          props: {
            initialApiState: {
              getHomePageInfo: {
                [populateHomePage]: defaults,
              },
            },
            status: 404,
          },
        };
      }
    }

    return {
      props: {
        initialApiState: {
          getHomePageInfo: {
            [populateHomePage]: defaults,
          },
        },
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const state: WrappedRawHomepageData = store.getState();

  return {
    props: {
      initialApiState: state,
    },
  };
}
