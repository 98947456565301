import Image from '@react/shared/components/Image';
import clsxm from '@sly/frontend/common/lib/clsxm';
import Carousel from '@sly/frontend/react/shared/components/Carousel';
import { assetPath } from 'sly/lib/path';
import { Button, Link } from 'sly/system';

const CtaSection = ({
  title,
  description,
  cta,
  ctaPath,
  path,
  alt,
  imgClassName,
  eventProps,
}: {
  title: string;
  description: string;
  cta: string;
  ctaPath: string;
  path: string;
  alt: string;
  imgClassName: string;
  eventProps: Record<string, any>;
}) => {
  return (
    <div className='mt-12 flex flex-col justify-between gap-6 rounded-xl border border-slate-lighter-90 p-8 sm:flex-row'>
      <div className='order-2 flex w-full flex-col gap-6 sm:order-1 sm:w-3/4'>
        <div>
          <p className='font-b-m font-medium text-slate-base'>{title}</p>
          <p className='font-b-m text-slate-base'>{description}</p>
        </div>
        <Link
          className='font-t-s-azo text-viridian-base'
          to={ctaPath}
          target='_blank'
          eventProps={eventProps}
        >
          {cta}
        </Link>
      </div>
      <div className='order-1 flex w-full sm:order-2 sm:w-1/4 sm:justify-end'>
        <Image
          src={path}
          alt={alt}
          width={200}
          height={200}
          className={imgClassName}
          layout='raw'
        />
      </div>
    </div>
  );
};

const ShowcaseCarousel = (props: any) => {
  if (!props?.children?.length) {
    return <></>;
  }

  return (
    <section className={clsxm('border-b px-8 py-12', props?.className)}>
      <div className='mx-auto max-w-5xl space-y-8'>
        <div className='max-w-3xl space-y-4'>
          {props.header && <h2 className='font-t-l'>{props.header}</h2>}
          {props.description && <p className='font-b-m'>{props.description}</p>}
        </div>
        <div className='space-y-4'>
          <Carousel
            itemsQty={props?.children?.length}
            className='w-full max-w-[70.5rems]'
            controlPosition={props.controlPosition || 'sides'}
            innerClassName='lg:auto-cols-[]'
            offset
          >
            {props.children}
          </Carousel>
          <div>
            {!!props.buttonLink && !!props.buttonText && (
              <Button
                to={props.buttonLink}
                variant='outlined'
                className='font-t-m-azo mx-auto w-full border-black text-slate-base sm:w-fit'
                eventProps={
                  { ...props.eventProps } || {
                    location: 'homepage',
                    text: props.buttonText,
                  }
                }
                target='_blank'
              >
                {props.buttonText}
              </Button>
            )}
            {!!props?.showKeySection && (
              <CtaSection
                title='Looking for home care options?'
                description='TheKey is the largest, and most trusted, in-home care provider.'
                cta='Explore home care options'
                ctaPath='/'
                path={assetPath('uploads/react-assets/image 1365.png')}
                alt='The Key'
                imgClassName='w-40 h-10'
                eventProps={{
                  location: 'homepage',
                  text: 'explore home care options',
                }}
              />
            )}

            {!!props?.showCompassSection && (
              <CtaSection
                title='Selling your home to finance senior living?'
                description='Compass Plus agents specialize in selling your home for top dollar.'
                cta='Schedule a free consultation now'
                ctaPath='/'
                path={assetPath('uploads/react-assets/Group 365.png')}
                alt='Compass Plus'
                imgClassName='w-44 h-6'
                eventProps={{
                  location: 'homepage',
                  text: 'schedule free consultation',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowcaseCarousel;
