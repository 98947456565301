import Image from '@react/shared/components/Image';
import { assetPath } from '@sly/frontend/common/lib/path';
import { Button, Link } from 'sly/system';

const pressLinks = [
  {
    path: assetPath('uploads/react-assets/Group.png'),
    alt: 'USA Today',
    article:
      'https://www.usatoday.com/story/money/personalfinance/2024/02/18/how-much-to-save-for-retirement-healthcare/72588899007',
  },
  {
    path: assetPath('uploads/react-assets/layer1.png'),
    alt: 'Medium',
    article:
      'https://medium.com/authority-magazine/arthur-bretschneider-of-seniorly-here-are-the-things-that-happened-in-my-childhood-that-impact-how-8e5b7ae1208f',
  },
  {
    path: assetPath('uploads/react-assets/Forbes_logo 1.png'),
    alt: 'Forbes',

    article:
      'https://www.forbes.com/councils/forbesbusinesscouncil/2023/12/07/the-elephant-in-the-senior-living-room-ageism-in-marketing',
  },
  {
    path: assetPath('uploads/react-assets/McKnights_SL_Color_1000X1000 1.png'),
    alt: 'McKnight’s Senior Living',
    article:
      'https://www.mcknightsseniorliving.com/home/columns/marketplace-columns/how-the-education-industry-prepared-me-for-senior-living',
  },
  {
    path: assetPath('uploads/react-assets/CBS_News_logo_(2020) 1.png'),
    alt: 'CBS News',
    article:
      'https://www.cbsnews.com/losangeles/video/california-ranks-high-in-new-study-measuring-tech-savvy-seniors',
  },
  {
    path: assetPath('uploads/react-assets/image 1476.png'),
    alt: 'Healthcare Business Today',
    article:
      'https://www.healthcarebusinesstoday.com/why-price-transparency-in-senior-living-is-no-longer-a-nice-to-have',
  },
];

const ShowcasePress = () => {
  return (
    <section className='border-b px-8 py-12'>
      <div className='mx-auto max-w-5xl space-y-12'>
        <h2 className='font-t-l'>Find us in the press</h2>
        <div className='space-y-8'>
          <div className='grid grid-cols-2 gap-8 sm:grid-cols-3'>
            {pressLinks.map(({ path, alt, article }, index) => (
              <Link
                key={index}
                className='flex h-32 items-center justify-center gap-4 rounded-xl border border-slate-lighter-90 py-8 px-6 transition delay-75 ease-in-out hover:bg-slate-lighter-97'
                to={article}
                target='_blank'
                eventProps={{
                  location: 'homepage - press',
                  text: alt,
                }}
              >
                <Image
                  src={path}
                  alt={alt}
                  layout='raw'
                  className='w-full rounded-xl object-cover sm:w-9/12 md:w-7/12'
                  width={340}
                  height={238}
                />
              </Link>
            ))}
          </div>
          <Button
            variant='outlined'
            className='mx-auto w-full border-black text-slate-base sm:w-fit'
            href='/company/press'
            eventProps={{
              location: 'homepage - press',
              text: 'visit our press page',
            }}
            target='_blank'
          >
            Visit our press page
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ShowcasePress;
